export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32')
];

export const server_loads = [];

export const dictionary = {
		"/": [7],
		"/(authenticated)/account": [8,[2]],
		"/(authenticated)/achievements": [9,[2,3]],
		"/(authenticated)/achievements/all": [11,[2,3]],
		"/(authenticated)/achievements/[achievementName]": [10,[2,3]],
		"/(authenticated)/activity": [12,[2]],
		"/(authenticated)/broker/paypal": [13,[2]],
		"/(authenticated)/charities": [14,[2,4]],
		"/(authenticated)/charities/donations": [16,[2,4]],
		"/(authenticated)/charities/[campaignId]": [15,[2,4]],
		"/contact": [21],
		"/(authenticated)/delete": [17,[2]],
		"/(authenticated)/home": [18,[2]],
		"/locations": [22,[5]],
		"/locations/filter": [24,[5]],
		"/locations/search": [25,[5]],
		"/locations/[locationId]": [23,[5]],
		"/login": [26,[6]],
		"/login/confirm-email": [27,[6]],
		"/login/email-link": [28,[6]],
		"/login/login-link-sent": [29,[6]],
		"/login/password": [30,[6]],
		"/login/register": [31,[6]],
		"/login/signin": [32,[6]],
		"/(authenticated)/payoutsettings": [19,[2]],
		"/(authenticated)/vouchers": [20,[2]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.js';